import Api from "@/apis/Api";

export default {
	methods: {
		showAddModal() {
			this.resetModal();
			this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.add.title');
			this.modalOptions.icon = "$plus";
			this.modalOptions.buttons.unshift({
				label: this.$t('button.emailAddress.add'),
				color: "primary",
				onclick: (modal) => {
					modal.$refs.form.validate() && this.addEmailAccount(modal.formValues);
				},
			});
			this.modalOptions.formFields = [
				{
					label: this.$t('form.label.email'),
					type: "text-select",
					suffix: "@",
					tooltip: "tooltip.add_email_account.email",
					textName: "email",
					selectName: "domain",
					options: [{
						label: this.selectedEmailDomain.domain,
						value: this.selectedEmailDomain.domain
					}],
					rules: [(v) => (v && /.+@.+/.test(v)) ||
						this.$t('form.validation.required', {field: this.$t('form.label.email')})
					],
				},
				{
					label: this.$t('form.label.password'),
					name: "password",
					tooltip: "tooltip.add_email_account.password",
					type: "generatePassword",
					rules: [
						(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.password')}),
						(v) =>
							(v && v.length >= 6) ||
							this.$t('form.validation.minLength', {
								field: this.$t('form.label.password'),
								length: 6
							}),
					],
				},
				{
					label: this.$t('form.label.unlimitedQuota'),
					name: "unlimited_quota",
					tooltip: "tooltip.add_email_account.unlimited_quota",
					type: "checkbox",
					onChange: (values) => {
						for (const field of this.modalOptions.formFields) {
							if (field.name == "quota") {
								field.hidden = values.unlimited_quota;
							}
						}
					},
				},
				{
					label: this.$t('form.label.quota'),
					name: "quota",
					tooltip: "tooltip.add_email_account.quota",
					type: "text",
					hidden: true,
				},
			];
			this.modalOptions.item = {
				domain: this.selectedEmailDomain.domain,
				unlimited_quota: true,
			};
			this.modalOptions.open = true;
		},
		addEmailAccount(formData) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			if (formData.unlimited_quota) {
				formData.quota = 0;
			}
			Api.post(
				`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/accounts`,
				formData
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailAddress.add.success');
					this.highlightItem = {
						email: `${formData.email}@${formData.domain}`,
					};
					this.reloadEmailAccounts(this.selectedEmailDomain);

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},
		showEditModal(item) {
			this.resetModal();
			this.modalOptions.item = item;
			this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.update.title', {email: `<span style="text-wrap: nowrap;">${item.email}</span>`});
			this.modalOptions.icon = "$edit";
			this.modalOptions.buttons.unshift({
				label: this.$t('button.emailAddress.update'),
				color: "primary",
				onclick: (modal) => {
					modal.$refs.form.validate() &&
					this.updateEmailAccount(item, modal.formValues);
				},
			});
			this.modalOptions.formFields = [
				{
					label: this.$t('form.label.password'),
					name: "hiddenPassword",
					tooltip: "tooltip.edit_email_account.password",
					type: "hiddenPassword",
					onButtonClick: () => {
						for (const field of this.modalOptions.formFields) {
							if (field.name == "hiddenPassword") {
								field.hidden = true;
							}
							if (field.name == "password") {
								field.hidden = false;
							}
						}
					},
				},
				{
					label: this.$t('form.label.password'),
					name: "password",
					tooltip: "tooltip.edit_email_account.password",
					type: "generatePassword",
					rules: [
						(v) => !!v || this.$t('form.validation.required', {field: this.$t('form.label.password')}),
						(v) =>
							(v && v.length >= 6) ||
							this.$t('form.validation.minLength', {
								field: this.$t('form.label.password'),
								length: 6
							}),
					],
					hidden: true,
				},
				{
					label: this.$t('form.label.unlimitedQuota'),
					name: "unlimited_quota",
					tooltip: "tooltip.edit_email_account.unlimited_quota",
					type: "checkbox",
					onChange: (values) => {
						for (const field of this.modalOptions.formFields) {
							if (field.name == "quota") {
								field.hidden = values.unlimited_quota;
							}
						}
					},
				},
				{
					label: this.$t('form.label.quota'),
					name: "quota",
					tooltip: "tooltip.edit_email_account.quota",
					type: "text",
					hidden: item.disk_quota == "unlimited",
				},
			];
			if (item.disk_quota == "unlimited") {
				this.modalOptions.item = {unlimited_quota: true, quota: ""};
			} else {
				this.modalOptions.item = {
					unlimited_quota: false,
					quota: parseInt(item.disk_quota),
				};
			}

			this.modalOptions.open = true;
		},

		updateEmailAccount(item, formData) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			if (formData.unlimited_quota) {
				formData.quota = 0;
			}
			Api.put(
				`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/accounts/${item.email}`,
				formData
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailAddress.update.success');
					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.highlightItem = {email: item.email};
					this.reloadEmailAccounts(this.selectedEmailDomain);
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},
		showDeleteModal(item) {
			this.resetModal();
			this.modalOptions.item = item;
			this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.delete.title');
			this.modalOptions.color = "error";
			this.modalOptions.icon = "$alertwarning";

			this.modalOptions.formFields = [
				{
					message: `<b>${this.$t('message.confirmAction')}</b>`,
					label: this.$t('form.confirmDelete.emailAddress'),
					name: "confirm",
					type: "checkbox",
					required: true,
				},
			];
			this.modalOptions.buttons.unshift({
				label: this.$t('button.emailAddress.delete'),
				color: "error",
				onclick: (modal) => {
					modal.$refs.form.validate() && this.deleteAccount(item);
				},
			});
			this.modalOptions.open = true;
		},
		deleteAccount(item) {
			this.modalOptions.persistent = true;
			this.modalOptions.submitting = true;
			this.modalOptions.submittingSuccess = "";
			this.modalOptions.submittingError = "";

			Api.delete(
				`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/accounts/${item.email}`
			)
				.then(() => {
					this.modalOptions.submittingSuccess = this.$t('notification.hosting.emailAddress.delete.success');
					this.reloadEmailAccounts(this.selectedEmailDomain);

					this.$store.dispatch("addAlert", {
						success: true,
						successMessage: this.modalOptions.submittingSuccess,
					});
					this.modalOptions.open = false;
				})
				.catch((error) => {
					this.modalOptions.submittingError = Api.getErrorMessage(error);

					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: this.modalOptions.submittingError,
					});
				})
				.finally(() => {
					this.modalOptions.persistent = false;
					this.modalOptions.submitting = false;
				});
		},
		loadDetailsModal(item) {
			item.detailsButtonLoading = true;
			Api.cached()
				.get(
					`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/accounts/${item.email}`
				)
				.then((response) => {
					this.showDetailsModal(item, response.data.data);
					item.detailsButtonLoading = false;
				})
				.catch((error) => {
					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: Api.getErrorMessage(error),
					});
					item.detailsButtonLoading = false;
				})
				.finally(() => {
					item.detailsButtonLoading = false;
				});
		},
		showDetailsModal(item, details) {
			this.resetModal();
			this.modalOptions.item = item;
			this.modalOptions.title = this.$t('heading.hosting.emailAddress.modal.show.title');
			this.modalOptions.type = "email-details";
			this.modalOptions.icon = "$eye";

			this.modalOptions.message = details;
			this.modalOptions.open = true;
		},
		webmailSso(item) {
			item.webmailButtonLoading = true;
			Api.cached()
				.get(
					`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/accounts/${item.email}/webmail-sso-url`
				)
				.then((response) => {
					window.location.href = response.data.data.url;
				})
				.catch((error) => {
					this.$store.dispatch("addAlert", {
						success: false,
						errorMessage: Api.getErrorMessage(error),
					});
				})
				.finally(() => {
					item.webmailButtonLoading = false;
				});
		},
	}
}